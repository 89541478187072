import React from 'react'
import TopNavBarN from '../TopNavBarN'
function Faq() {
  return (
   
    <div>
    <TopNavBarN/>
      <h2>FAQ</h2>
    </div>
  )
}

export default Faq
